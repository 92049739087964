/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import {
  Button,
  CircularProgress,
  TextField,
  Box,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import storage from '../firestore/firestore';

export default function NewApplication() {
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [file, setFile] = React.useState(undefined);
  const [done, setDone] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [loanAmount, setLoanAmount] = React.useState('');
  const [fetchingRate, setFetchingRate] = React.useState(false);
  const [rate, setRate] = React.useState('');
  const [interestToPay, setInterest] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [itfChoice, setChoice] = React.useState('');
  const [tenure, setTenure] = React.useState('');
  const [validate1, setValidate1] = React.useState(false);
  const [validate2, setValidate2] = React.useState(false);
  const [validate3, setValidate3] = React.useState(false);

  const navigate = useNavigate();
  // functions

  const getOptions = async () => {
    try {
      await fetch('https://homeclass-proof-of-funds-api.vercel.app/api/options/services/itfoption/read', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          if (data.empty) {
            setOptions([]);
            setDone(true);
          } else {
            setOptions(data.options);
            setDone(true);
          }
        });
    } catch (error) {
      console.log(error);
      setDone(true);
    }
  };

  // fetch rate

  const fetchRate = async () => {
    if (!loanAmount || !tenure || !itfChoice) {
      alert('Loan Amount, Tenure and ITF Option cannot be empty');
    } else if (loanAmount < 5000000 || loanAmount > 40000000) {
      alert('We only offer ITF between 5,000,000 to 40,000,000');
    } else {
      setFetchingRate(true);

      await fetch('https://homeclass-proof-of-funds-api.vercel.app/api/options/services/itfoption/get-rate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          option: itfChoice,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setInterest(0);
            setFetchingRate(false);
            alert(data.error);
          } else {
            setInterest(Number((data.rate / 100) * loanAmount));
            setRate(data.rate);
            setFetchingRate(false);
          }
        });
    }
  };

  const savedUser = JSON.parse(localStorage.getItem('savedUser'));
  const handleSaveItf = async () => {
    if (!interestToPay || interestToPay === 0 || !loanAmount || !tenure) {
      alert('Please fill in all sections before proceeding');
    } else if (!validate1 || !validate2 || !validate3) {
      alert('Please agree to all necessary conditions before proceeding');
    } else {
      await fetchRate();

      setSaving(true);
      await fetch('https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/new/application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itfOption: itfChoice,
          loanAmount,
          rate,
          interestToPay,
          tenure,
          cus_email: savedUser.cus_email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.error) {
            setSaving(false);
            alert(data.error);
          } else {
            localStorage.setItem('application', JSON.stringify(data.trans));
            // save to billing database since no payment has been made

            
            fetch('https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/new/application/billing', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                itfOption: itfChoice,
                loanAmount,
                rate,
                tenure,
                interestToPay,
                applicationId: data.trans.applicationId,
                customerId: data.trans.customerId,
              }),
            })
              .then((res) => res.json())
              .then((bill) => {
                if (bill.error) {
                  setSaving(false);
                  window.alert(JSON.stringify(bill.error));
                } else {
                  localStorage.setItem('billingId', data.billingId)
                  setSaving(false);
                  setIsSuccessful(true);

                }
              });
          }
        });
    }
  };

  const handleSavePayment = () => {
    if (!file) {
      setSaving(false);
      alert('Please attach a proof of payment');
    } else {
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);

      const billingId = JSON.parse(localStorage.getItem('billingId'));
      const application = JSON.parse(localStorage.getItem('application'));
      if (!billingId) {
        // fix this error at home.

        setSaving(false);
        alert('No Transaction Initialized');
        setIsSuccessful(false);
      } else {
        setSaving(true);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
              default:
                console.log('error uploading file');
            }
          },
          (error) => {
            // Handle unsuccessful uploads

            console.log(error);

            setSaving(false);
            alert('Could not upload file. If this persists, please contact the support');
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(async (proofUrl) => {
              // save and update db;

              await fetch('https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/itf-proof', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  billingId,
                  proofUrl,
                  customerId: application.customerId,
                }),
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.error) {
                    console.log(response.error);
                    alert(response.error);
                    setSaving(false);
                  } else {
                    setSaving(false);

                    setUploaded(true);
                    localStorage.setItem('completed', 'completed');
                    localStorage.removeItem('transaction');
                  }
                });
            });
          }
        );
      }
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <>
      {!isSuccessful ? (
        <>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            style={{ margin: '30px' }}
            noValidate
            autoComplete="off"
          >
            <h4 align="center" style={{ marginLeft: '30px' }}>
              Create New ITF Transaction
            </h4>{' '}
            <br />
            <Alert severity="info" style={{ margin: '17px 20px' }}>
              <AlertTitle>Important Information</AlertTitle>
              <p>
                <strong>
                  <p>Follow the following step to create a new transaction</p>
                  <br />
                  <ol>
                    <l1>Fill up the following form by indicationg Amount, ITF Option, etc.</l1>
                    <li>Check the Amount to Pay by clicking the corresponding Button</li>
                    <li>Agree to Neccessary Terms and conditions</li>
                    <li>Click on Save Transaction Button to create your Transaction</li>
                    <li>Make a transfer of the amount displayed as corresponding Interest of your choice of ITf</li>
                    <li>Click on Proceed to Invoice page</li>
                    <li>From the overview page, click on the application Id</li>
                    <li>Scroll down and click on upload proof to upload the payment proof</li>
                    <li>Await payment confirmation... This is usually done within 2 hours</li>
                  </ol>
                </strong>
              </p>
            </Alert>
            <TextField
              id="outlined-select-option"
              select
              label="Select ITF Option"
              fullWidth
              value={itfChoice}
              onChange={(e) => setChoice(e.target.value)}
              helperText="Please select your ITF Option"
            >
              {!done ? (
                <MenuItem value="">
                  <i>Loading ITF Option</i>
                </MenuItem>
              ) : done && options.length === 0 ? (
                <Typography>NO ITF OPTION AVAILABLE</Typography>
              ) : (
                options.map((item) => (
                  <MenuItem key={item.itfoption} value={item.itfoption}>
                    {item.itfoption} @ {item.rate}% per month
                  </MenuItem>
                ))
              )}
            </TextField>
            <TextField
              id="outlined-select-tenure"
              select
              label="Select Desired Tenure"
              fullWidth
              value={tenure}
              onChange={(e) => setTenure(e.target.value)}
              helperText="Please select your Tenure"
            >
              <MenuItem value={'1 month'}>{'1 month'}</MenuItem>
              <MenuItem value={'2 month'}>{'2 month'}</MenuItem>
              <MenuItem value={'3 month'}>{'3 month'}</MenuItem>
              <MenuItem value={'4 month'}>{'4 month'}</MenuItem>
              <MenuItem value={'5 month'}>{'5 month'}</MenuItem>
              <MenuItem value={'6 month'}>{'6 month'}</MenuItem>
            </TextField>
            <TextField
              type="number"
              label="Enter Amount"
              placeholder="5Million - 40Million"
              fullWidth
              value={loanAmount}
              helperText="Enter amount for disbursement"
              onChange={(e) => setLoanAmount(e.target.value)}
            />
            <small>
              <b>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'NGN',
                }).format(Number(loanAmount))}
              </b>
            </small>
            {fetchingRate ? (
              <small>
                <i>Calculating...</i>
              </small>
            ) : (
              <Button variant="text" color="primary" onClick={fetchRate}>
                Click to get Amount to Pay
              </Button>
            )}
            <TextField
              value={new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'NGN',
                minimumFractionDigits: 2,
              }).format(interestToPay)}
              InputProps={{
                readOnly: true,
              }}
              helperText="Amount to Pay displayed here"
              fullWidth
              type="text"
              placeholder="Amount to Pay will display here"
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox value={validate1} onChange={(e) => setValidate1(e.target.checked)} />}
                label="I agree to Terms and Conditions"
              />
              <FormControlLabel
                control={<Checkbox value={validate2} onChange={(e) => setValidate2(e.target.checked)} />}
                label="I agree to No back-dating policy"
              />
              <FormControlLabel
                control={<Checkbox value={validate3} onChange={(e) => setValidate3(e.target.checked)} />}
                label="I agree to pay 9 days before tenure expiration(if extending)"
              />
            </FormGroup>
          </Box>
          {saving ? (
            <CircularProgress />
          ) : (
            <Button color="primary" variant="contained" style={{ margin: '40px' }} onClick={handleSaveItf}>
              Save Transaction
            </Button>
          )}
        </>
      ) : (
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '100%' },
          }}
          style={{ margin: '30px' }}
          noValidate
          autoComplete="off"
        >
          <h4 align="center" style={{ marginLeft: '30px' }}>
            Payment Proof Upload
          </h4>{' '}
          <br />
          <Alert severity={uploaded ? 'success' : 'info'} style={{ margin: '17px 20px' }}>
            <AlertTitle>{uploaded ? 'Payment Proof Upload Successful' : 'Bank Account Information'}</AlertTitle>
            <p>
              {!uploaded ? (
                <strong>
                  Kindly transfer NGN{new Intl.NumberFormat().format(interestToPay)}  to the following account details <br />
                  <p>
                    <b>Account Name: HomeClass and Victorias Tutors </b>
                  </p>
                  <p>
                    <b>Account Number: 1015867572</b>
                  </p>
                  <p>
                    <b>Bank Name: Zenith Bank</b>
                  </p>
                <br />
                  <p>Once, you have made you payment, kindly go to your invoices by clicking on the button below<br />
                    On the invoicing page, please click on the application Id, you will be redirected to the payment overview page. 
                    Scroll down to upload your payment proof
                  </p>
                </strong>
              ) : (
                <strong>
                  Your Payment proof of {/* {itf.interestToPay} */} has been uploaded successfully <br />
                  <p>
                    Payment Confirmation will be done within 24 hours. Once confirmed, a confirmation email will be sent
                    to you
                    <br />
                    And after which, some documents to fill up will be sent to you fill up and upload
                  </p>
                </strong>
              )}
            </p>
          </Alert>
          <div>
            
            
              <Button variant="contained" fullWidth color="primary" onClick={()=>{
                navigate("/dashboard/billing",{replace: true})
              }}>
                Proceed to Invoice page
              </Button>
            
          </div>
        </Box>
      )}
    </>
  );
}
