/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
// material
import { Stack, Container, Typography, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import MaterialTable from 'material-table'
import Page from '../components/Page';

export default function Payments() {

  const savedUser = JSON.parse(localStorage.getItem('savedUser'));
  const [payment, setPayment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  

  useEffect(() => {
    if (!savedUser) {
      navigate('/');
    }else{
        setIsLoading(true);
      fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/itf-payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: savedUser.customerId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setIsLoading(false);
            alert("data.error");
            navigate("/dashboard/app");
          } else if (data.no_trans) {
            setIsLoading(false);
          } else {
            setPayment(data.transaction);
            setIsLoading(false);
          }
        });
    }
    
  }, []);
  return (
    <>
      <Page title="Portal | Payments">
        <Container>
        
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Payments
            </Typography>
            
          </Stack>

          {
              isLoading?<CircularProgress />:
              <MaterialTable
              title="Application Payment Details"
              columns={[
                {
                  title: "#Application ID",
                  field: "applicationId",
                  render:(rowData)=><Link to={`/dashboard/transaction/${rowData._id}`}>
                          {rowData.applicationId}
                      </Link>
                },

                {
                  title: "Payment Tranch",
                  field: "paymentTranch",
                },

                {
                  title: "Payment Status",
                  field: "paymentStatus",
                  render: (rowData) => {
                    let value = "";
                    if (rowData.paymentStatus === 0) {
                      value = "Pending";
                    } else if (rowData.paymentStatus === 1) {
                      value = "Awaiting Confirmation";
                    } else {
                      value = "Confirmed and Paid";
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: "Complete Status",
                  field: "completeStatus",
                  render: (rowData) => {
                    let value = "";
                    if (rowData.completeStatus === 0) {
                      value = "Pending";
                    } else if (rowData.completeStatus === 1) {
                      value = "Process Ongoing";
                    } else {
                      value = "Confirmed and Completed";
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: "Payment Proof",
                  field: "paymentProof",
                  render: (rowData) => {
                    let value = "";
                    let option = null;
                    if (!rowData.paymentProof) {
                      value = "";
                      option = <Typography>No Payment Proof yet</Typography>;
                    } else {
                      value = rowData.paymentProof;
                      option = <a href={value}>Download Proof</a>;
                    }

                    return option;
                  },
                },
                {
                  title: "Transaction Date",
                  field: "createdAt",
                },
                {
                  title: "Updated Date",
                  field: "updatedAt",
                },
                
              ]}
              data={payment}
              options={{
                pageSize: payment.length > 3? 5: 2,
                exportButton: true,
              }}
            />
          }

          
        </Container>
      </Page>
    </>
  );
}
