import React, {useState, useEffect} from 'react'
import { Grid, Container, Typography } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppTrafficBySite, AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {

  const [payment, setPayment] = useState([])
  const [invoice, setInvoice] = useState([])
  const [application, setApplication] = useState([])
  const savedUser = JSON.parse(localStorage.getItem('savedUser'))
  const navigate = useNavigate()

  useEffect(()=>{
    if(!savedUser){
        navigate("/",{replace: true})
    }else{
      // fetch("https://proof-of-funds.herokuapp.com/api/support-ticket-count", {
      //   method:"POST",
      //   headers:{
      //     "Content-Type":"application/json"
      //   },
      //   body: JSON.stringify({
      //     customerId: savedUser.customerId
      //   })
      // }).then(res=>res.json())
      // .then(data=>{
      //   if(data.error){
      //     console.log(data.error)
      //   }else{
      //     setOpened(Number(data.open))
      //     setAnswered(Number(data.answered))
      //     setClosed(Number(data.closed))
      //     setTotal(Number(data.open) + Number(data.closed) + Number(data.answered))
      //   }
      // })

      fetch('https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/get-applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: savedUser.customerId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          
          if (data.error) {
            console.log(data.error);
          } else if (data.transactions) {
            setApplication(data.transactions);
          } else {
            setApplication([]);
          }
          
        });

        fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/itf-payments", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerId: savedUser.customerId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              console.log(data.error)
            } else if (data.no_trans) {
              console.log(data.no_trans)
            } else {
              setPayment(data.transaction);
              
            }
          });

          fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/itf-billings", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customerId: savedUser.customerId,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                console.log(data.error)
              } else if (data.no_bills) {
                console.log(data.no_bills)
              } else {
                setInvoice(data.billing);
              }
            });
    }
  },[])
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Applications available" total={application.length} icon={'eva:done-all-fill'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Payments" total={payment.length} color="info" icon={'eva:credit-card-fill'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Billing Invoices"
              total={invoice.length}
              color="warning"
              icon={'eva:upload-fill'}
            />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}
