// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'ITF Application',
    path: '/dashboard/application',
    icon: getIcon('eva:checkmark-fill'),
  },
  {
    title: 'Account Management',
    path: '/dashboard/account',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'payments',
    path: '/dashboard/payments',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'billing(Invoices)',
    path: '/dashboard/billing',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
