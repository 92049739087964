import React, { useEffect, useState } from 'react';
import { Container, Stack, Typography, Grid, Box, TextField, Alert, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppTrafficBySite } from '../sections/@dashboard/app';

export default function SupportCenter() {
  const [opened, setOpened] = useState(0);
  const [closed, setClosed] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState('error');
  const [message, setMessage] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [subject, setSubject] = React.useState('');
  const [problem, setProblem] = React.useState('');

  const savedUser = JSON.parse(localStorage.getItem('savedUser'));
  const navigate = useNavigate();

  const submitTicket = async () => {
    if (!savedUser) {
      navigate('/', { replace: true });
    } else {
      setLoad(true);
      await fetch('https://homeclass-proof-of-funds-api.vercel.app/api/itf-support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject,
          message: problem,
          customerId: savedUser.customerId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setMessage(data.error);
            setAlert('error');
            setOpen(true);
          } else {
            setMessage(data.success);
            setAlert('success');
            setOpen(true);
            setSubject('');
            setProblem('');
          }
          setLoad(false);
        });
    }
  };

  useEffect(() => {
    if (!savedUser) {
      navigate('/', { replace: true });
    } else {
      fetch("https://homeclass-proof-of-funds-api.vercel.app/api/support-ticket-count", {
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          customerId: savedUser.customerId
        })
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log(data.error)
        }else{
          setOpened(Number(data.open))
          setAnswered(Number(data.answered))
          setClosed(Number(data.closed))
          setTotal(Number(data.open) + Number(data.closed) + Number(data.answered))
        }
      })
    }
  }, []);

  return (
    <Page title="Portal | Support Center">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Support Center
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '80%' },
              }}
              noValidate
              autoComplete="off"
            >
              <h4 align="center" style={{ marginLeft: '30px' }}>
                Create a Support Ticket
              </h4>{' '}
              <br />
              {open ? <Alert severity={alert}>{message}</Alert> : null}
              <TextField
                variant="outlined"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="problem summary"
                helperText="Problem subject"
                fullWidth
              />
              <TextField
                id="filled-multiline-flexible"
                label="Issue to be solved"
                value={problem}
                onChange={(e)=>setProblem(e.target.value)}
                multiline
                helperText="please state the issue you will like us to solve for you"
                maxRows={10}
                variant="outlined"
              />

              {
                load?<CircularProgress />:
                <Button variant='contained' color='primary' fullWidth onClick={submitTicket}>Submit Ticket</Button>
              }
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AppTrafficBySite
              title="Support Ticket Analysis"
              list={[
                {
                  name: 'Opened',
                  value: { opened },
                  icon: <Iconify icon={'eva:question-mark-circle-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Answered',
                  value: { answered },
                  icon: <Iconify icon={'eva:question-mark-circle-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Closed',
                  value: { closed },
                  icon: <Iconify icon={'eva:question-mark-circle-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Total',
                  value: { total },
                  icon: <Iconify icon={'eva:question-mark-circle-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
