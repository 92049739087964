/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React from 'react'
import {
    TextField,
    Button,
    Alert,
    CircularProgress
} from '@mui/material'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../firestore/firestore";


export default function UploadTransactionDocument(props) {
    const [file, setFile] = React.useState(undefined)
    // eslint-disable-next-line prefer-destructuring
    const applicationId = props.applicationId
    const customerId = props.customerId

    const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState("error");
  const [message, setMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  
  const UploadTransactionDocument = async()=>{
      if(!file){
          setMessage('file is required...Please choose a document')
          setAlert('error')
          setOpen(true)
      }else if(!file.name.includes('zip')){
        setMessage('Please upload a zip file containing all the required document criteria')
          setAlert('error')
          setOpen(true)
      }
      else{
          setLoad(true)
          const fileName = new Date().getTime() + file.name;
          const storageRef = ref(storage, fileName);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${  progress  }% done`);
              switch (snapshot.state) {
                case "paused":
                  console.log("Upload is paused");
                  break;
                case "running":
                  console.log("Upload is running");
                  break;
                default:
                  console.log("error uploading file");
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              setMessage(
                "Could not upload file. If this persists, please contact the support"
              );
              console.log(error);
              setMessage(error)
              setAlert('error')
              setOpen(true)
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(async (proofUrl) => {
                // save and update db;
  
                await fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/itf-document", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    documentUrl: proofUrl,
                    customerId,
                    applicationId
                  }),
                })
                  .then((res) => res.json())
                  .then((response) => {
                    if (response.error) {
                      setMessage(
                        response.error
                      );
                      console.log(response.error);
                      setMessage(response.error);
                      setAlert('error');
                      setOpen(true)
                    } else {
                      setMessage(response.success);
                      setAlert('success')
                      setOpen(true)
                      window.location.reload()
                    }
                    setLoad(false)
                  });
              });
            }
          );
      }
  }

    
  return (
    <>
        {
          open?<Alert severity={alert}>{message}</Alert>: null
      }
      <TextField
          fullWidth
          type="file"
          variant="outlined"
          onChange={(e) => setFile(e.target.files[0])} /><Button style={{ marginTop: "15px" }} disabled={load} variant="contained" fullWidth onClick={UploadTransactionDocument}>
            Upload Transaction Document &nbsp; &nbsp; {load ? <CircularProgress /> : null}
          </Button>
      
    </>
  )
}
