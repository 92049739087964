/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import Page from '../components/Page';
import UploadPaymentProof from "../components/dialogs/UploadPaymentProof";
import UploadTransactionDocument from "../components/dialogs/UploadTransactionDocument";
import ExtensionRequest from "../components/dialogs/ExtensionRequest";

export default function CardTransactionDetails() {
  const { id } = useParams();
  const savedUser = JSON.parse(localStorage.getItem("savedUser"));
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [showOption, setShowOption] = useState("");
  const [billId, setBillId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [applicationId, setApplicationId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchTransaction = async () => {
    if (!savedUser) {
      navigate("/dashboard/app");
    } else {
      setIsLoading(true);
      await fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/get-transaction-detail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          applicationId: id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.error) {
            alert(data.error);
            navigate("/dashboard/app");
          } else if (data.no_trans) {
            alert(data.no_trans);
            navigate("/dashboard/app");
          } else {
            console.log(data.payload);
            setDetails(data.payload);
          }
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, []);

  return (
    <>
    <Page title="Portal | Transaction Overview">
        <Container>
        
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Transaction Overview
            </Typography>
            
          </Stack>

          <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Action Requirement Portal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!showOption
              ? null
              : showOption === "extend"
              ? "To proceed with your extension request, please choose the number of weeks you want to extend for"
              : showOption === "proof"
              ? "To proceed with uploading your payment proof, please choose your file to Upload your payment proof in order to faciliate your application"
              : "To proceed with your Application processing, Please upload a zip file containing all the filled documents you downloaded for your transaction"}
          </DialogContentText>
          {!showOption ? null : showOption === "extend" ? (
            <ExtensionRequest billId={billId} />
          ) : showOption === "proof" ? (
            <UploadPaymentProof billId={billId} customerId={customerId} />
          ) : (
            <UploadTransactionDocument
              applicationId={applicationId}
              customerId={customerId}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <div
        
      >
        
        {isLoading ? (
          <Stack spacing={1}>
            <Skeleton variant="text" />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Stack>
        ) : (
          <div>
            <h4 align="center" style={{ marginLeft: "30px" }}>
              Application Details
            </h4>{" "}
            <br />
            <MaterialTable
              title="Application Details"
              columns={[
                {
                  title: "#Application ID",
                  field: "applicationId",
                },

                {
                  title: "Loan Amount",
                  field: "loanAmount",
                  render: (rowData) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "NGN",
                    }).format(Number(rowData.loanAmount)),
                },

                {
                  title: "ITF Option",
                  field: "itfOption",
                },

                {
                  title: "Rate",
                  field: "rate",
                },
                {
                  title: "Tenure",
                  field: "tenure",
                },
                {
                  title: "Interest To Pay",
                  field: "interestToPay",
                  render: (rowData) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "NGN",
                    }).format(Number(rowData.interestToPay)),
                },

                {
                  title: "Application Status",
                  field: "completeStatus",
                  render: (rowData) => {
                    let value = "";
                    if (rowData.completeStatus === 0) {
                      value = "Pending";
                    } else if (rowData.completeStatus === 1) {
                      value = "In-Processing";
                    } else {
                      value = "Completed";
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: "Transaction Date",
                  field: "createdAt",
                },
                {
                  title: "Updated Date",
                  field: "updatedAt",
                },
              ]}
              data={details}
              options={{
                pageSize: 1,
                exportButton: true,
              }}
            />
            <Divider />
            <br />
            <h4 align="center" style={{ marginLeft: "30px" }}>
              Payment and Billing Details
            </h4>{" "}
            <br />
            <MaterialTable
              title="Application Payment Details"
              columns={[
                {
                  title: "#Application ID",
                  field: "applicationId",
                },

                {
                  title: "Payment Tranch",
                  field: "paymentTranch",
                },

                {
                  title: "Payment Status",
                  field: "paymentStatus",
                  render: (rowData) => {
                    let value = "";
                    if (rowData.paymentStatus === 0) {
                      value = "Pending";
                    } else if (rowData.paymentStatus === 1) {
                      value = "Awaiting Confirmation";
                    } else {
                      value = "Confirmed and Paid";
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: "Complete Status",
                  field: "completeStatus",
                  render: (rowData) => {
                    let value = "";
                    if (rowData.completeStatus === 0) {
                      value = "Pending";
                    } else if (rowData.completeStatus === 1) {
                      value = "Process Ongoing";
                    } else {
                      value = "Confirmed and Completed";
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: "Payment Proof",
                  field: "paymentProof",
                  render: (rowData) => {
                    let value = "";
                    let option = null;
                    if (!rowData.paymentProof) {
                      value = "";
                      option = <Typography>No Payment Proof yet</Typography>;
                    } else {
                      value = rowData.paymentProof;
                      option = <a href={value}>Download Proof</a>;
                    }

                    return option;
                  },
                },
                {
                  title: "Transaction Date",
                  field: "createdAt",
                },
                {
                  title: "Updated Date",
                  field: "updatedAt",
                },
                {
                  title: 'Due Status',
                  field: 'isActive',
                  render: (rowData) => {
                    let value = '';
                    if (rowData.isActive && rowData.paymentStatus === 0) {
                      value = 'Due for Payment';
                    } else if(rowData.isActive && rowData.paymentStatus === 1){
                      value = 'Awaiting confirmation'
                    }else if(rowData.isActive && rowData.paymentStatus === 2){
                      value = 'No payment required'
                    }
                    else {
                      value = 'Not yet due for payment'
                    }
                    return <Typography>{value}</Typography>;
                  },
                },
                {
                  title: 'Active Due Date',
                  field: 'isActiveDate',
                },
                {
                  title: "Extension Request",
                  field: "extension",
                  render: (rowData) => {
                    let option = null;
                    if (
                      rowData.paymentTranch > 1 &&
                      rowData.paymentStatus === 0 && !rowData.extensionRequest
                    ) {
                      option = (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setBillId(rowData._id);
                            setShowOption("extend");
                            handleClickOpen();
                          }}
                        >
                          Extend
                        </Button>
                      );
                    } else if (rowData.extensionRequest) {
                      option = (
                        <small style={{ color: "red" }}>
                          Tenure extension has been applied
                        </small>
                      );
                    } else {
                      option = (
                        <small style={{ color: "red" }}>
                          First Payment Tranch or paid bills cannot be extended
                        </small>
                      );
                    }
                    return option;
                  },
                },
              ]}
              data={details}
              options={{
                pageSize: 3,
                exportButton: true,
              }}
            />
            <Divider />
            <br />
            <h4 align="center" style={{ marginLeft: "30px" }}>
              Upload and Download Documents
            </h4>{" "}
            <br />
            <MaterialTable
              title="Application Download and Upload"
              columns={[
                {
                  title: "#Application ID",
                  field: "applicationId",
                },
                {
                  title: "Download",
                  field: "download",
                  render: (rowData) => {
                    let option = null;
                    if (rowData.paymentStatus > 1 && rowData.paymentTranch === 1) {
                      option = (
                        <Button variant="contained" color="primary" onClick={()=>{
                          fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/send-download-link",{
                            method:"POST",
                            headers:{
                              "Content-Type":"application/json"
                            },
                            body:JSON.stringify({
                              applicationId: rowData.applicationId,
                              customerId: rowData.customerId
                            })
                          }).then(res=>res.json())
                          .then(data=>{
                            if(data.error){
                              alert(data.error)
                            }else{
                              alert(data.success)
                            }
                          })
                        }}>
                          Download Transaction Document
                        </Button>
                      );
                    } else {
                      option = (
                        <small style={{ color: "red" }}>
                          Document not yet available for download
                        </small>
                      );
                    }

                    return option;
                  },
                },
                {
                  title: "Upload",
                  field: "upload",
                  render: (rowData) => {
                    let option = null;
                    if (
                      rowData.paymentStatus > 1 &&
                      rowData.paymentTranch === 1 
                    ) {
                      option = (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/check-itf-document", {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                applicationId: rowData.applicationId,
                              }),
                            })
                              .then((res) => res.json())
                              .then((data) => {
                                if (data.exist) {
                                  alert(data.exist);
                                } else {
                                  setApplicationId(rowData.applicationId);
                                  setCustomerId(rowData.customerId);
                                  setShowOption("document");
                                  handleClickOpen();
                                }
                              });
                          }}
                        >
                          Upload Transaction Document
                        </Button>
                      );
                    } else if (rowData.paymentStatus < 1) {
                      option = (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setBillId(rowData._id);
                            setCustomerId(rowData.customerId);
                            setShowOption("proof");
                            handleClickOpen();
                          }}
                        >
                          Upload Payment Proof
                        </Button>
                      );
                    } else {
                      option = (
                        <small style={{ color: "red" }}>
                          No Upload required yet
                        </small>
                      );
                    }

                    return option;
                  },
                },
              ]}
              data={details}
              options={{
                pageSize: 1,
                search: false,
                columnSorting: false,
              }}
            />
          </div>
        )}
      </div>
        </Container>
    </Page>

      
    </>
  );
}
