import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Alert, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('error');
  const [open, setOpen] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string()
      .min(11, 'Must be at least 11')
      .max(15, 'must not exceed 15')
      .required('Phone Number is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsLoading(true)
        fetch("https://homeclass-proof-of-funds-api.vercel.app/api/customer/signup",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                cus_first_name: formik.values.firstName,
                cus_last_name: formik.values.lastName,
                cus_email: formik.values.email.toLowerCase(),
                cus_phone: formik.values.phone,
                cus_password: formik.values.password,
                platform: "ITF"
            })

        }).then(res=>res.json())
        .then(data=>{
            if(data.error){
                
              setMessage(data.error);
              setAlert('error');
              setOpen(true);
            }else{
              fetch('https://homeclass-proof-of-funds-api.vercel.app/api/customer/login', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  cus_email: formik.values.email.toLowerCase(),
                  cus_password: formik.values.password,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.error) {
                    setMessage(data.error);
                    setAlert('error');
                    setOpen(true);
                    setIsLoading(false)
                  } else {
                    localStorage.setItem('savedUser', JSON.stringify(data.savedUser));
                    localStorage.setItem('token', JSON.stringify(data.token));
        
                    navigate('/dashboard/app', { replace: true });
                    setIsLoading(false)
                  }
                  
                })
                .catch((error) => {
                  console.log(error);
                  setMessage("Unable to connect to sever... Please check your connection");
                  setAlert('error');
                  setOpen(true);
                  setIsLoading(false)
                });
            }
        }).catch(error=>{
            console.log(error)
            setIsLoading(false)
        })

        
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {open ? (
          <Alert severity={alert} style={{ margin: '10px' }}>
            {message}
          </Alert>
        ) : null}

        {isLoading ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="phone"
            type="text"
            label="Phone Number"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
