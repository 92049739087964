/* eslint-disable react/prop-types */
import React from "react";
import { FormControl, Button, Alert, CircularProgress } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";

export default function ExtensionRequest(props) {
    // eslint-disable-next-line prefer-destructuring
    const billId = props.billId
  const [extension, setExtension] = React.useState("7 days");
  const [open, setOpen] = React.useState(false)
  const [alert, setAlert] = React.useState('error')
  const [message, setMessage] = React.useState('')
  const [load, setLoad] = React.useState(false)
  

  const handleChange = (event) => {
    setExtension(event.target.value);
  };

  const performExtension = async() =>{
      setLoad(true)
    await fetch("https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/billing/itf-extension",{
        method: "POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
            billId,
            extensionTimeFrame: extension
        })
    }).then(res=>res.json())
    .then(data=>{
        if(data.error){
            setMessage(data.error)
            setAlert('error')
            setOpen(true)
        }else{
            setMessage(data.success)
            setAlert('success')
            setOpen(true)
            window.location.reload()
        }
        setLoad(false)
    })
  }


  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        {
            open?<Alert severity={alert}>{message}</Alert>: null
        }

        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Extension Time frame
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={extension}
            onChange={handleChange}
          >
            <FormControlLabel
              value="7 days"
              control={<Radio />}
              label="One Week"
            />
            <FormControlLabel
              value="14 days"
              control={<Radio />}
              label="Two Weeks"
            />
            <FormControlLabel
              value="21 days"
              control={<Radio />}
              label="Three Weeks"
            />
            <FormControlLabel
              value="30 days"
              control={<Radio />}
              label="Four Weeks"
            />
          </RadioGroup>
        </FormControl>

        <Button onClick={performExtension} variant="contained" disabled={load} style={{ marginTop: "20px" }} fullWidth>
          Extend Application &nbsp; &nbsp; {load?<CircularProgress />: null}
        </Button>
      </Box>
    </>
  );
}
