import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Application from './pages/Application';
import AccountManagement from './pages/AccountManagement';
import Payments from './pages/Payments';
import Billing from './pages/Billing';
import CardTransactionDetails from './pages/TransactionOverview';
import SupportCenter from './pages/SupportCenter';
import CheckEmail from './pages/CheckEmail';
import ChangePassword from './pages/ChangePassword';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'application', element: <Application /> },
        { path: 'account', element: <AccountManagement /> },
        { path: 'payments', element: <Payments /> },
        { path: 'billing', element: <Billing /> },
        { path: 'support-center', element: <SupportCenter /> },
        { path: 'transaction/:id', element: <CardTransactionDetails /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'forgot-password', element: <CheckEmail /> },
        {path: 'account/verification/:token', element: <ChangePassword />},
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
