/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// material
import { Stack, Button, Container, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';

export default function AccountManagement() {
  const savedUser = JSON.parse(localStorage.getItem('savedUser'));
  const navigate = useNavigate();
  // Dialog box component
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // end of dialog box component

  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleChangePassword = async () => {
    if (!newPassword) {
      setError(true);
      setSuccess(false);
      setMessage("Choose a New Password");
      
    } else if (!retypePassword) {
      setError(true);
      setSuccess(false);
      setMessage("Retype New Password");
      
    } else if(newPassword !== retypePassword){
      setError(true);
      setSuccess(false);
      setMessage("Password do not match");
      
    }
    else {
      setIsLoading(true);
      await fetch("https://homeclass-proof-of-funds-api.vercel.app/api/password/change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cus_email: savedUser.cus_email,
          cus_password: newPassword,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.error) {
            setError(true);
            setSuccess(false);
            setMessage(data.error);
          } else {
            setError(false);
            setSuccess(true);
            setMessage(data.success);
            setNewPassword("")
            setRetypePassword("")
          }
        });

      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (!savedUser) {
      navigate('/');
    } 
  }, [navigate, savedUser]);
  return (
    <>
      <Page title="Account Management">
        <Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Change Your Password"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ marginBottom: "15px" }}
            >
              Use the Change Password wizard to change your Password. This means
              you will use your new password to sign in when next you sign in to your account.
            </DialogContentText>
            
            {error ? (
              <Alert severity="error" style={{ marginBottom: "15px" }}>
                <AlertTitle>Error</AlertTitle>
                {message}
              </Alert>
            ) : success ? (
              <Alert severity="success" style={{ marginBottom: "15px" }}>
                <AlertTitle>Successful</AlertTitle>
                {message}
              </Alert>
            ) : null}
            
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Your New Password"
              label="New Password"
              style={{ marginBottom: "15px" }}
              value={newPassword}
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Retype Your New Password"
              label="Retype Password"
              style={{ marginBottom: "15px" }}
              value={retypePassword}
              type="password"
              onChange={(e) => setRetypePassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangePassword}
              disabled={isLoading}
            >
              {isLoading ? (
                <Typography variant="h6" style={{ fontStyle: "italic" }}>
                  Changing Password
                </Typography>
              ) : (
                "Change Password"
              )}
            </Button>
          </DialogActions>
        </Dialog>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Account Management
            </Typography>
            <Button onClick={handleClickOpen} variant="contained" to="#">
              Change Password
            </Button>
          </Stack>

          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="right">#Customer ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell align="right">Last Name</TableCell>
            <TableCell align="right">Email Address</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {savedUser.customerId}
              </TableCell>
              <TableCell align="right">{savedUser.cus_first_name}</TableCell>
              <TableCell align="right">{savedUser.cus_last_name}</TableCell>
              <TableCell align="right">{savedUser.cus_email}</TableCell>
              <TableCell align="right">{savedUser.cus_phone}</TableCell>
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
          
        </Container>
      </Page>
    </>
  );
}
