/* eslint-disable no-restricted-globals */
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// material
import { Stack, Button, Container, Typography, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
// components
import MaterialTable from 'material-table';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import NewApplication from '../components/dialogs/NewApplication';

// ----------------------------------------------------------------------

// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Application() {
  const savedUser = JSON.parse(localStorage.getItem('savedUser'));
  const [transactions, setTransactions] = useState([]);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (!savedUser) {
        navigate('/');
      } else {
        setLoad(true);
        fetch('https://homeclass-proof-of-funds-api.vercel.app/api/services/itf/get-applications', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customerId: savedUser.customerId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.transactions);
            if (data.error) {
              console.log(data.error);
            } else if (data.transactions) {
              setTransactions(data.transactions);
            } else {
              setTransactions([]);
            }
            setLoad(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Page title="ITF Application">
      <Container>
        <Dialog fullScreen open={status ? false : open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                New Int'l Travel Funds Transaction
              </Typography>

              <Button color="inherit" onClick={handleClose}>
                Terms & Conditions
              </Button>
            </Toolbar>
          </AppBar>
          <NewApplication />
        </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ITF Application
          </Typography>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Application
          </Button>
        </Stack>

        {load ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            title="Application Details"
            columns={[
              {
                title: '#Application ID',
                field: 'applicationId',
              },

              {
                title: 'Loan Amount',
                field: 'loanAmount',
                render: (rowData) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'NGN',
                  }).format(Number(rowData.loanAmount)),
              },

              {
                title: 'ITF Option',
                field: 'itfOption',
              },

              {
                title: 'Rate',
                field: 'rate',
              },
              {
                title: 'Tenure',
                field: 'tenure',
              },
              {
                title: 'Interest To Pay',
                field: 'interestToPay',
                render: (rowData) =>
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'NGN',
                  }).format(Number(rowData.interestToPay)),
              },

              {
                title: 'Application Status',
                field: 'applicationStatus',
                render: (rowData) => {
                  let value = '';
                  if (rowData.applicationStatus === 0) {
                    value = 'Pending';
                  } else if (rowData.applicationStatus === 1) {
                    value = 'In-Processing';
                  } else {
                    value = 'Completed';
                  }
                  return <Typography>{value}</Typography>;
                },
              },
              {
                title: 'Document Upload Status',
                field: 'uploadStatus',
                render: (rowData) => {
                  let value = '';
                  if (rowData.uploadStatus === 0) {
                    value = 'No document uploaded';
                  } else if (rowData.uploadStatus === 1) {
                    value = 'Transaction Document uploaded';
                  } else {
                    value = 'Another Upload required';
                  }
                  return <Typography>{value}</Typography>;
                },
              },
              {
                title: 'Transaction Date',
                field: 'createdAt',
              },
              {
                title: 'Updated Date',
                field: 'updatedAt',
              },
            ]}
            data={transactions}
            options={{
              pageSize: transactions.length > 3 ? 5 : 2,
              exportButton: true,
            }}
          />
        )}
      </Container>
    </Page>
  );
}
